import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormatUtils {
    constructor(private decimalPipe: DecimalPipe) {}

    percentFormat(value: number): string {
        if (isNaN(value) || value === null) {
            return '';
        }

        return `${value}%`;
    }

    formatAmount(value: any, currency: boolean = false): string {
        if (!value) return '';
        if (typeof value === 'string') value = this.desformatAmount(value);
        const formattedValue = this.decimalPipe.transform(Number(value), '1.2-2', 'es');
        return currency ? `${formattedValue} €` : formattedValue;
    }

    desformatAmount(value: any): number {
        let valueWithoutFormat = value.replace(/\./g, '').replace(',', '.');
        return parseFloat(valueWithoutFormat);
    }

    formatNumber(value?: any): string {
        if (typeof value === 'string') value = this.desformatNumber(value);
        const formattedValue = this.decimalPipe.transform(Number(value), '1.0-0', 'es');
        return formattedValue;
    }

    formatPorcentage(value: any): string {
        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            return '';
        }
        const roundedValue = Math.floor(numericValue);
        const limitedValue = Math.min(Math.max(roundedValue, 1), 100); // Limitar entre 1 y 100
        return limitedValue.toFixed(0);
    }

    desformatNumber(value: any): number {
        if(value) {
            let valueWithoutFormat = value.replace(/\./g, '').replace(',', '.');
            return parseFloat(valueWithoutFormat);
        }
    }

    formatTime(value: string): string {
        let formattedValue = value.replace(/[^0-9]/g, ''); // Elimina caracteres no numéricos

        if (formattedValue.length > 2) {
            formattedValue = formattedValue.slice(0, 2) + ':' + formattedValue.slice(2);
        }

        return formattedValue;
    }

    formatAmericanDate(dateInput: any): string {
        // Formatear la fecha a YYYY-MM-DD
        const year = dateInput.getFullYear();
        const month = dateInput.getMonth() + 1;
        const day = dateInput.getDate();

        // con dígitos
        const monthFormated = month < 10 ? `0${month}` : month;
        const dayFormated = day < 10 ? `0${day}` : day;

        return `${year}-${monthFormated}-${dayFormated}`;
    }

    formatSpainDate(dateInput: any): string {
        if (typeof dateInput === 'string') {
            const dateString = dateInput.replace('Z', '');
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear().toString();
            return `${day}/${month}/${year}`;
        } else {
            return '-';
        }
    }

    formatExportDate(dateInput: any): string {
        // Formatear la fecha a YYYY-MM-DD
        const year = dateInput.getFullYear();
        const month = dateInput.getMonth() + 1;
        const day = dateInput.getDate();

        // con dígitos
        const monthFormated = month < 10 ? `0${month}` : month;
        const dayFormated = day < 10 ? `0${day}` : day;

        return `${dayFormated}-${monthFormated}-${year}`;
    }

    formatPhoneNumber(phone: string):string{
        const formattedPhone = phone.replace(/[ ]/g, '')
        return formattedPhone;
    }

    parseFloat(value: any): number {
        if (typeof value === 'string') {
            value = value.replace('.', '').replace(',', '.');
        }
        return parseFloat(value) ?? null;
    }
}
